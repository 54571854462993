<template>
  <div class="head">
    <header-tab
      :showBackArrow="showBackArrow"
      :fn="fnItem"
      :title="title"
    ></header-tab>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import headerTab from "./headerTab.vue";
export default {
  props: {
    fnItem: {
      type: Function,
    },
    showBackArrow: {
      type: Boolean,
      default: true,
    },
    title: {
      default: "",
    },
  },
  created(){
    console.log(this.title,'titletitle')
  },
  data() {
    return {};
  },
  components: {
    headerTab,
  },
};
</script>

<style lang="scss" scoped>
.head {
  padding-top: 80px;
  height: 100%;
  position: relative;
}
</style>