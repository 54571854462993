<template>
  <head-top>
    <div class="pay-successful">
      <div class="successful-icom">
        <img src="../../assets/chenggong.png" alt="" />
        <p class="title">订单支付成功</p>
        <p class="content">广州逸林假日酒店</p>
        <p class="yuding">预订成功</p>
      </div>
      <div class="chakan">
        <van-button type="info" @click="viewDetails">查看订单详情</van-button>
      </div>
    </div>
  </head-top>
</template>

<script>
import headTop from "../../components/head.vue";
export default {
  components: {
    headTop,
  },
  methods: {
    viewDetails() {
      this.$router.push({
        name: "payDetails",
        query: {
          orderId: this.$route.query.orderId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-successful {
  height: 100%;
  padding-top: 100px;
  .successful-icom {
    text-align: center;
    img {
      width: 100px;
      height: 100px;
    }
    .title {
      font-size: 36px;
      font-weight: 600;
    }
    .content {
      font-size: 32px;
    }
    .yuding {
      font-size: 30px;
      color: rgb(124, 120, 120);
    }
  }
  .chakan {
    margin-top: 20px;
    padding: 0 50px;
    button {
      width: 100%;
    }
  }
}
</style>