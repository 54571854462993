<template>
  <div class="headerTab" :style="{ 'background-color': bgColor }">
    <div v-if="showBackArrow" class="back-arrow" @click="clickBack()"></div>
    <slot name="middle">
      <span class="title" v-html="titleName"></span>
    </slot>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerTab",
  props: {
    title: {
      default: "",
    },
    bgColor: {
      default: "",
    },
    fn: {
      type: Function,
    },
    showBackArrow: {
      default: true,
    },
  },
  data() {
    return {
      titleName: "",
    };
  },
  watch: {
    title(val) {
      console.log(val, "888888");
      this.titleName = this.title || this.$route.meta.title;
    },
  },
  created() {
    this.titleName = this.title || this.$route.meta.title;
  },
  methods: {
    clickBack() {
      if (this.fn) {
        this.fn();
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.headerTab {
  min-height: 80px;
  width: 100%;
  background-color: white; //#A260A4
  display: flex;
  align-items: center;
  text-align: center;
  position: fixed;
  justify-content: center;
  color: #000;
  font-size: 36px;
  z-index: 3;
  top: 0;
  left: 0;
  border-bottom: 1px solid #f1f1f1; /*no*/
  .back-arrow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: "";
      width: 24px;
      height: 24px;
      border-bottom: 1px solid #333; /*no*/
      border-left: 1px solid #333; /*no*/
      transform-origin: center;
      transform: rotateZ(45deg);
      margin-left: -10px;
    }
  }
  .title {
    display: inline-block;
    width: 520px;
    overflow: hidden;
    text-align: center;
    line-height: 50px;
    font-weight: 500;
    color: black;
    min-height: 50px;
  }
  .right {
    height: 100%;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 10px;
    color: black;
  }
}
</style>
